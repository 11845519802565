import React from "react";
export default function Technology() {
  return (
    <div id="technology">
      <p className="font-display text-white text-center xl:text-left text-4xl xl:text-6xl xl:sticky xl:top-0 mt-8 mb-12 xl:m-0">
        TECHNOLOGY
      </p>
      <div className="flex flex-col text-center gap-24 xl:gap-0 xl:pt-32 xl:pb-32 xl:text-center xl:flex-row xl:flex-wrap font-monospace w-full xl:text-sm 2xl:text-lg text-white items-center justify-center xl:snap-center xl:h-screen">
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">MOTORS</p>
          <p className="w-5/6">
            A wide spectrum of electric machine can be proposed and specifically
            chosen for each application in order to guarantee the performances
            in terms of electric power, weight, mechanical coupling and cooling.
            We use Axial Flux three phases brushless machines.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">SOFTWARE</p>
          <p className="w-5/6">
            The software of all the electronic devices is designed and developed
            in house, so we are responsible for the correct operation of the
            electronics. All the control logic of the system are specifically
            studied and designed according with each project specifics.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">INVERTER</p>
          <p className="w-5/6">
            A large variety of controller is used. The power devices integrated
            inside the inverters use all the latest technologies available on
            the market, from Silicon power MOSFET or Silicon Carbide MOSFET
            transistor to Silicon IGBT bipolar transistors.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">DASHBOARD</p>
          <p className="w-5/6">
            The multiple screens dashboard can be fully personalize following
            the customer requirements. The dashboard communicate with all the
            electronic system using the CAN protocol. All the information and
            Nav systems are available and can be shown to the driver.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">IOT</p>
          <p className="w-5/6">
            WiFi expansion board for the remote control of the main battery
            information can be integrated inside the electronic system. The
            electronic circuit upload on the cloud the data from the battery
            pack and, in any minute, from each part of the word, it is always
            possible to monitor these information.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">COMMUNICATION</p>
          <p className="w-5/6">
            The main protocol used is the Controller Area Network (CAN bus),
            which is perfectly compatible with the NMEA 2000 used in marine
            application. The node identification of all the data can be
            programmed in order to avoid address already used in the system with
            which make the interface.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">BATTERY PACK</p>
          <p className="w-5/6">
            High voltage battery packs, from 115V up to 700V, are specifically
            designed for each application. We used Lithium Ion or Lithium
            polymer chemistry in order to guarantee the desired performances in
            terms of output current and energy stored related to the available
            spaces and weight target.
          </p>
        </div>
        <div className="flex flex-col xl:w-1/4 items-center justify-center">
          <p className="font-display text-3xl text-[#F2CB05]">BMS</p>
          <p className="w-5/6">
            The BMS is integrated inside the battery pack. The voltage of each
            cell is constantly monitored and a passive balance system works for
            increase the stability of the system. The BMS verifies the
            temperature inside the pack, controls the flow of current, drives
            internal and external contactor and communicate by CAN bus protocol
            all the main information to the electronic system.
          </p>
        </div>
      </div>
    </div>
  );
}
