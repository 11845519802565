import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
export default function Footerinfo() {
  return (
    <div className="flex flex-col items-center justify-center w-full xl:w-1/4 font-monospace text-xl text-white grow-0 sm:flex-wrap">
      <img className="w-32 h-32 mb-6" src={logo}></img>
      <div className="flex flex-col items-center justify-center mb-6 w-full text-2xl xl:flex-wrap">
      <p>Terra Modena Mechatronic srl</p>
      <p>tel/fax +39 059214370</p>
      <p>p.iva 03621670367</p>
      <p>ccia Mo 403561</p>
      <p> info@terramodena.eu</p>
      </div>
      <div className="flex flex-col gap-3 items-center justify-center xl:flex-wrap">
      <Link
        className="font-display transition ease-in-out delay-150 hover:scale-105 hover:text-[#f2cb05] duration-500"
        to="/privacy"
      >
        PRIVACY & POLICY
      </Link>
      <a
        className="font-display transition ease-in-out delay-150 hover:scale-105 hover:text-[#f2cb05] duration-500"
        href="https://docs.google.com/forms/d/e/1FAIpQLSdRR7269FLLgaadzD4DBjMxbL0CbfzuTztNTmSGSGsEdSXWVw/viewform"
      >
        {" "}
        SEND US YOUR CV
      </a>
      </div>
    </div>
  );
}
