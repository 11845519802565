import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <div className="flex flex-col xl:flex-row bg-slate-800 p-3 gap-8 xl:gap-12 text-white font-display items-center justify-center text-2xl w-3/5 self-center">
     <img src={logo} className="w-16 h-16 m-3" />
     <a href="#whoweare" className="transition ease-in-out delay-150 hover:scale-105 active:text-[#f2cb05] xl:hover:text-[#f2cb05] duration-200 shrink-0">WHO WE ARE</a>
      <a href="#newfact" className="transition ease-in-out delay-150 hover:scale-105 active:text-[#f2cb05] xl:hover:text-[#f2cb05] duration-200 shrink-0">NEW FACT</a>
      <a href="#achievement" className="transition ease-in-out delay-150 hover:scale-105 active:text-[#f2cb05] xl:hover:text-[#f2cb05] duration-200 shrink-0">ACHIEVEMENTS</a>
      <a href="#technology" className="transition ease-in-out delay-150 hover:scale-105 active:text-[#f2cb05] xl:hover:text-[#f2cb05] duration-200 shrink-0">TECHNOLOGY</a>
    </div>
  );
}
