import React, { useEffect, useRef } from "react";
import * as additional from "../css/additional.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

export default function CookieConsent() {
  const banner = useRef();
  useEffect(() => {
    if (localStorage.getItem("CookieConsent")) {
      ReactGA.initialize("UA-152574628-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const handleAccept = () => {
    ReactGA.initialize("UA-152574628-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    localStorage.setItem("CookieConsent", true);
    console.log(banner.current);
    banner.current.classList.add("hidden");
  };
  if (!localStorage.getItem("CookieConsent")) {
    return (
      <div
        ref={banner}
        className="flex flex-col items-center justify-center gap-4 left-0 bottom-0 fixed w-full  z-10 xl:h-1/4 p-8 ml-auto mr-auto font-monospace text-xl text-white bg-slate-800"
      >
        <div>
          <p>
            We use cookies and similar methods to recognize visitors and
            remember their preferences. We also use them to measure ad campaign
            effectiveness, target ads and analyze site traffic. To learn more
            about these methods, including how to disable them, view our{" "}
            <Link to="/privacy" className="text-[#f2cb05]">
              Cookie Policy.
            </Link>
          </p>
        </div>
        <div className="flex flex-col lg:flex-row ">
          <button
            onClick={handleAccept}
            className="transition ease-in-out delay-150 hover:scale-105 duration-500 hover:bg-[#f2cb05] text-white focus:scale-95 w-32 h-9"
          >
            ACCEPT
          </button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
