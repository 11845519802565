import React from "react";

export default function Whoweare() {
  return (
    <div id="whoweare">
      <p className="font-display text-white text-4xl text-center xl:text-left  xl:text-6xl xl:sticky px-2 xl:px-0 xl:top-0">
        WHO WE ARE
      </p>
      <div className="flex flex-col text-center xl:pt-20 xl:text-left px-4 md:px-8 xl:px-0 mt-4 xl:mt-0 xl:h-screen items-center justify-center gap-12 xl:snap-center">
        <p className="font-monospace text-white xl:text-base  2xl:text-xl xl:text-2xl w-full xl:w-3/5 justify-self-center">
          Terra Modena Mechatronic (TMM) was founded in 2015 by Dario Calzavara
          with the mission of designing and manufacturing electrical powertrains with Axial Flux motors from 20kW to 400kW 
          with energy by Lithium batteries for high performance boats.<br></br> We  carry out projects with absolute confidentiality criteria and without using our Brand.
        </p>
        <p className="font-display text-2xl  2xl:text-3xl xl:ml-42 text-[#F2CB05]">
          FUTURE ENERGY TECHNOLOGY
        </p>
        <p className="font-monospace  text-white xl:text-base  2xl:text-xl xl:text-2xl w-full xl:w-3/5">
          Together with a Group of international Researchers we are developing a
          clean new low energy nuclear technology the NEW ENERGY CLEAN TECH 
          (provisional name) for private powerboats, yachts, superyachts and
          also for ferries, shuttles and commercial vessels for public use. The
          new technology will make a great contribution to the preservation of
          the seas by providing a source of clean and abundant energy avoiding
          the costs and the autonomy limitation of the current batteries. The
          Capex and operational costs will be dramatically reduced. More to come
          in April 2022.
        </p>
      </div>
    </div>
  );
}
