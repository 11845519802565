import { React, useState, useEffect, useRef } from "react";
import sg1 from "../assets/sg1.png";
import catamaran from "../assets/catamaran.png";
import t5 from "../assets/t5_monohull.png";
import anvera from "../assets/anvera_e_lab.png";
import shuttle from "../assets/shuttle_vessel.png";
import hybrid from "../assets/hybrid.png";
import "../css/additional.css";
export default function Achievement() {
  const snap = useRef();
  const offset = useRef(0);
  const [snap_counter, setsnap_counter] = useState(0);
  useEffect(() => {
    if (snap.current) {
      offset.current = snap.current.children[1].offsetWidth;
      snap.current.children[1].children[0].classList.add("year_color");
      snap.current.children[1].children[1].classList.add("achievement_text");
    }
  },[]);

  const Scroll = (e) => {
    const currentscrollX = e.target.scrollLeft;
    if (Number.isInteger(currentscrollX / offset.current)) {
      Array.from(snap.current.children, (el) => {
        el.children[0].classList.remove("year_color");
        el.children[1].classList.remove("achievement_text");
      });
      snap.current.children[
        currentscrollX / offset.current + 1
      ].children[0].classList.add("year_color");
      snap.current.children[
        currentscrollX / offset.current + 1
      ].children[1].classList.add("achievement_text");
      setsnap_counter(currentscrollX / offset.current);
      console.log(currentscrollX);
    }
  };

  const auto_Scroll = (e) => {
    Array.from(snap.current.children, (el) => {
      el.children[0].classList.remove("year_color");
      el.children[1].classList.remove("achievement_text");
    });
    snap.current.scrollTo(offset.current * e.target.text, 0);
    snap.current.children[
      (offset.current * e.target.text) / offset.current + 1
    ].children[0].classList.add("year_color");
    snap.current.children[
      (offset.current * e.target.text) / offset.current + 1
    ].children[1].classList.add("achievement_text");
    setsnap_counter((offset.current * e.target.text) / offset.current);
    console.log(offset.current * e.target.text);
  };
  return (
    <div id="achievement">
      <p className="font-display object-scale-down max-w-full text-white text-center xl:text-left text-4xl xl:text-6xl xl:sticky xl:top-0 my-8 mb-4 xl:m-0">
        ACHIEVEMENTS
      </p>
      <div
        onScroll={Scroll}
        ref={snap}
        className="snap-x snap-mandatory snap-center xl:h-screen w-full p-6 xl:p-0 text-white font-monospace text-xl flex flex-row overflow-x-scroll invisible-scrollbar achievement_list"
      >
        <div class="snap-center shrink-0 w-1/3 hidden xl:block">
          <div class="shrink-0"></div>
          <div></div>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center w-full  xl:w-1/3 shrink-0"
          id="0"
        >
          <p className="text-5xl font-display">2014</p>
          <p className="p-9 text-center">
            SG 1 the electric racing car with Terra Modena EIPS EVO 1
          </p>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center  w-full  xl:w-1/3 shrink-0"
          id="1"
        >
          <p className="text-5xl font-display">2017</p>
          <p className="p-9 text-center">
            Racing catamaran with Terra Modena EIPS EVO 2
          </p>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center  w-full  xl:w-1/3 shrink-0"
          id="2"
        >
          <p className="text-5xl font-display">2018</p>
          <p className="p-9 text-center">
            T5 monohull boat with Terra Modena EVO 3
          </p>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center  w-full  xl:w-1/3 shrink-0"
          id="3"
        >
          <p className="text-5xl font-display">2019</p>
          <p className="p-9 text-center">
            ANVERA E Lab sponsored by Tecnoelettra is the winner of the Monaco
            speed Solar & Energy challenge on July 5th with 74kmh. The motor is
            an evolution of the SG 1 racing car Terra Modena EIPS EVO 1
          </p>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center  w-full  xl:w-1/3 shrink-0"
          id="4"
        >
          <p className="text-5xl font-display">2021</p>
          <p className="p-9 text-center">Electric shuttle vessel</p>
        </div>
        <div
          className="flex flex-col items-center justify-center snap-center  w-full  xl:w-1/3 shrink-0"
          id="5"
        >
          <p className="text-5xl font-display">2022</p>
          <p className="p-9 text-center">
            The Terra Modena hybrid parallel propulsion for the monohull boat is
            under construction
          </p>
        </div>
        <div class="snap-center shrink-0  w-1/3 hidden xl:block">
          <div class="shrink-0"></div>
          <div></div>
        </div>
      </div>
      <div className="dotstyle dotstyle-circlegrow sticky bottom-0 flex items-center justify-center">
        <ul>
          <li className={snap_counter === 0 ? "current" : ""}>
            <a onClick={auto_Scroll}>0</a>
          </li>
          <li className={snap_counter === 1 ? "current" : ""}>
            <a onClick={auto_Scroll}>1</a>
          </li>
          <li className={snap_counter === 2 ? "current" : ""}>
            <a onClick={auto_Scroll}>2</a>
          </li>
          <li className={snap_counter === 3 ? "current" : ""}>
            <a onClick={auto_Scroll}>3</a>
          </li>
          <li className={snap_counter === 4 ? "current" : ""}>
            <a onClick={auto_Scroll}>4</a>
          </li>
          <li className={snap_counter === 5 ? "current" : ""}>
            <a onClick={auto_Scroll}>5</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
