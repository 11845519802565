import React from "react";
import * as additional from "../css/additional.css";
export default function Contactus() {
  return (
    <form className="w-full xl:w-1/4" name="contact" method="post">
      <div className="flex flex-col text-white font-display text-xl gap-2">
        <p className="text-3xl self-center">CONTACT US</p>
        <input type="hidden" name="form-name" value="contact" />
        <label>Name</label>
        <input
          type="text"
          name="name"
          required
          className="h-10 transition ease-in-out delay-150 hover:scale-105 duration-500 focus:border-[2px]"
        ></input>
        <label>Email</label>
        <input
          type="email"
          name="email"
          required
          className="h-10 transition ease-in-out delay-150 hover:scale-105 duration-500 focus:border-[2px]"
        ></input>
        <label>Object</label>
        <input
          type="text"
          name="object"
          required 
          className="h-10 transition ease-in-out delay-150 hover:scale-105 duration-500 focus:border-[2px]"
        ></input>
        <label>Message</label>
        <textarea name="message"  required className="h-46 text-lg transition ease-in-out delay-150 hover:scale-105 duration-500 focus:border-[2px]"></textarea>
        <div className="flex flex-row items-center justify-center">
            <label className="emailcheck transition ease-in-out delay-150 hover:scale-105 duration-500">
              <input type="checkbox" required></input>
              <span className="checkbox"></span>
            </label>
          <label className="font-monospace text-sm  ml-14">
            By accepting this you allow us to save your email and reply through
            your email
          </label>
        </div>
        <button
          type="submit"
          className="self-center transition ease-in-out delay-150 hover:scale-105 duration-500 hover:bg-[#f2cb05] text-white focus:scale-95 w-32 h-9"
        >
          SEND
        </button>
      </div>
    </form>
  );
}
