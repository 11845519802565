import React from "react";
import * as additional from "../css/additional.css";
export default function Newsletter() {
  return (
    <form className="w-full xl:w-1/4" name="contact" method="post">
      <div className="flex flex-col font-monospace text-white gap-3  justify-center">
        <p className="font-display text-3xl self-center">
          RECEIVE OUR BROCHURE
        </p>
        <input
          type="email"
          className="h-10 transition ease-in-out delay-150 hover:scale-105 duration-500 focus:border-[2px]"
          placeholder="Insert here your email"
        ></input>
        <div className="flex flex-row items-center justify-center">
          <label className="emailcheck transition ease-in-out delay-150 hover:scale-105 duration-500">
            <input type="checkbox" required></input>
            <span className="checkbox"></span>
          </label>
          <label className="text-sm ml-14">
            By accepting this you allow us to save your email and send to you
            our brochure
          </label>
        </div>
        <button
          type="submit"
          className="font-display text-xl self-center transition ease-in-out delay-150 hover:scale-105 duration-500 hover:bg-[#f2cb05] text-white focus:scale-95 w-32 h-9"
        >
          REQUEST
        </button>
      </div>
    </form>
  );
}
