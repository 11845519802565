import logo from "./logo.svg";
import Navbar from "./components/Navbar";
import { Routes, Route, Link } from "react-router-dom";
import Whoweare from "./components/Whoweare";
import Newfact from "./components/Newfact";
import Achievement from "./components/Achievement";
import Contactus from "./components/Contactus";
import Newsletter from "./components/Newsletter";
import Footerinfo from "./components/Footerinfo";
import locandina from "./assets/intro.png";
import locandina_mobile from "./assets/portrait.jpg";
import Technology from "./components/Technology";
import Privacypolicy from "./components/Privacypolicy";
import CookieConsent from "./components/CookieConsent";
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacypolicy />} />
      </Routes>
    </div>
  );
}

function Home() {
  return (
    <div className="flex flex-col xl:flex-none space-y-16 p-4 xl:snap-y snap-mandatory xl:p-12 h-screen w-screen overflow-scroll">
      <CookieConsent/>
      <div className="flex flex-col items-center justify-center h-screen snap-center xl:pb-0 xl:pt-0">
        <Navbar />
        <div className="flex flex-col items-center justify-center w-3/5 h-full">
          <img src={locandina}className="hidden md:block"></img>
          <img src={locandina_mobile} className="md:hidden"></img>
        </div>
      </div>
      <Whoweare className="mb-24"/>
      <Newfact />
      <Technology />
      <Achievement/>
      <footer className="snap-center xl:h-screen">
        <div className="flex flex-col p-4 xl:p-0 xl:flex-row items-center justify-between gap-12 w-full h-full xl:h-screen">
          <Footerinfo />
          <Newsletter />
          <Contactus />
        </div>
        <p className="font-display xl:sticky text-center w-full bottom-0 text-white text-sm">
          © 2022 TERRA MODENA MECHATRONIC SRL
        </p>
      </footer>
      <div className="min-h-[2rem] xl:hidden">
      </div>
    </div>
  );
}

export default App;
