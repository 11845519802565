import React from "react";
import * as additional from "../css/additional.css";

import { Link } from "react-router-dom";
export default function Privacypolicy() {
  return (
    <div className="flex flex-col items-center justify-center">
        <Link to="/" className="font-display text-3xl mt-4 text-[#f2cb05]">
        BACK TO WEBSITE
        </Link>
      <div className="flex flex-col font-monospace text-xl text-white p-12 h-screen overflow-y-auto gap-12">
        <p className="font-display item-center">Cookie Policy for TerraModena </p>
        <p>
          This is the Cookie Policy for terramodena, accessible from
          www.terramodena.eu <br />
          What Are Cookies As is common practice with almost all professional
          websites this site uses cookies, which are tiny files that are
          downloaded to your computer, to improve your experience. This page
          describes what information they gather, how we use it and why we
          sometimes need to store these cookies. We will also share how you can
          prevent these cookies from being stored however this may downgrade or
          ‘break’ certain elements of the sites functionality.
        </p>
        <p>
          For more general information on cookies see the Wikipedia article on
          HTTP Cookies. How We Use Cookies We use cookies for a variety of
          reasons detailed below.
        </p>
        <p>
          Unfortunately in most cases there are no industry standard options for
          disabling cookies without completely disabling the functionality and
          features they add to this site. It is recommended that you leave on
          all cookies if you are not sure whether you need them or not in case
          they are used to provide a service that you use. Disabling Cookies You
          can prevent the setting of cookies by adjusting the settings on your
          browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit.
          <br /> Disabling cookies will usually result in also disabling certain
          functionality and features of the this site. Therefore it is
          recommended that you do not disable cookies.
        </p>
        The Cookies We Set Forms related cookies When you submit data to through
        a form such as those found on contact pages or comment forms cookies may
        be set to remember your user details for future correspondence. Third
        Party Cookies In some special cases we also use cookies provided by
        trusted third parties.
        <p>
          The following section details which third party cookies you might
          encounter through this site.
        </p>
        <p>
          This site uses Google Analytics which is one of the most widespread
          and trusted analytics solution on the web for helping us to understand
          how you use the site and ways that we can improve your experience.
        </p>
        <p>
          These cookies may track things such as how long you spend on the site
          and the pages that you visit so we can continue to produce engaging
          content.
        </p>
        <p>
          For more information on Google Analytics cookies, see the official
          Google Analytics page.
        </p>
        <p>
          More Information Hopefully that has clarified things for you and as
          was previously mentioned if there is something that you aren’t sure
          whether you need or not it’s usually safer to leave cookies enabled in
          case it does interact with one of the features you use on our site.
          This Cookies Policy was created with the help of the Cookies Policy
          Template Generator and the WebTerms Generator.
        </p>
        <p>
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
          <br /> Email: Info@terramodena.eu
        </p>
      </div>
    </div>
  );
}
