import React from "react";

export default function Newfact() {
  return (
    <div id="newfact">
      <p className="font-display text-white text-center xl:text-left text-4xl xl:text-6xl xl:sticky xl:top-0 my-8 mb-4 xl:m-0 ">NEW FACT</p>

      <div className="flex flex-col xl:h-screen items-center justify-center text-xl xl:text-2xl xl:snap-center xl:p-28">
        <div className="flex flex-col xl:h-3/4 px-4 md:px-8 xl:px-0 items-center justify-center">
          <div className="flex items-center justicy-center w-full xl:w-2/3 h-1/3 m-3 border-[1px] border-[#F2CB05]">
            <p className="font-monospace text-white text-xl xl:text-2xl  p-5 xl:p-10 text-center ">
              On Febrary 2022, Terra Modena Mechatronic signed an exclusive
              license agreement for the marketing and use of the NEW ENERGY
              CLEAN TECH (NECT) for maritime applications.
            </p>
          </div>
          <div className="flex items-center justicy-center w-full xl:w-2/3 h-1/3 m-3 border-[1px] border-[#F2CB05]">
            <p className="font-monospace text-white text-xl xl:text-2xl xl:m-3 p-5 xl:p-10 text-center ">
              NECT will be presented at mid-April 2022 following a DEMO test at
              the Polytechnic University of Milan, Department of Nuclear Energy.
            </p>
          </div>
          <div className="flex items-center justicy-center w-full xl:w-2/3 h-1/3 m-3 border-[1px] border-[#F2CB05]">
            <p className="font-monospace text-white text-xl xl:text-2xl xl:m-3 p-5 xl:p-10 text-center">
              We are currently developing integrated electrical systems for
              several applications from private yacht to commercial vessel for
              public use.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
